@keyframes slide-in-left {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.header {
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
}
.header.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-left;
}
.body,
.content,
.footer,
.header {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  gap: 2.5rem;
  opacity: 0;
  text-align: right;
  font-size: 10rem;
  color: #fff;
  font-family: Poppins;
}
.content.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-right;
}
.body,
.footer {
  align-items: flex-start;
}
.footer {
  justify-content: flex-end;
  opacity: 0;
}
.footer.animate {
  animation: 1s ease 0s 1 normal forwards slide-in-left;
}
.body {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: space-between;
  padding: 0.63rem;
  gap: 2.5rem;
  text-align: left;
  font-size: 1.5rem;
  color: #cbcbcb;
  font-family: Poppins;
}
