.logoIcon {
  width: 5rem;
  height: 5rem;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .logoIcon {
    height: 4rem;
    width: 4rem;
  }
}
@media screen and (max-width: 960px) {
  .logoIcon {
    height: 3rem;
    width: 3rem;
  }
}
@media screen and (max-width: 430px) {
  .logoIcon {
    height: 2rem;
    width: 2rem;
  }
}
