.creative,
.title {
  margin: 0;
}
.title {
  height: auto;
  position: relative;
  font-size: 10rem;
  letter-spacing: 0.03em;
  line-height: 100%;
  font-weight: 100;
  font-family: Poppins;
  color: #fff;
  text-align: right;
  display: inline-block;
}
@media screen and (max-width: 1200px) {
  .title {
    font-size: 8rem;
  }
}
@media screen and (max-width: 960px) {
  .title {
    font-size: 6rem;
  }
}
@media screen and (max-width: 430px) {
  .title {
    font-size: 4rem;
  }
}
