.home {
  width: 100dvw;
  height: 100dvh;
  position: relative;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem 2.5rem 1.25rem;
  box-sizing: border-box;
}
@media screen and (max-width: 960px) {
  .home {
    height: 100dvh;
    width: 100%;
    align-content: space-between;
  }
}
