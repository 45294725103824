.subtitle {
  margin: 0;
  height: 3rem;
  position: relative;
  font-size: 3rem;
  line-height: 100%;
  font-weight: 200;
  font-family: Poppins;
  color: #ff3566;
  text-align: left;
  display: inline-block;
}
@media screen and (max-width: 1200px) {
  .subtitle {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 960px) {
  .subtitle {
    font-size: 2rem;
  }
}
@media screen and (max-width: 430px) {
  .subtitle {
    font-size: 1.5rem;
  }
}
