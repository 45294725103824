.copyright {
  height: 1.25rem;
  position: relative;
  font-size: 1.5rem;
  line-height: 100%;
  font-weight: 200;
  font-family: Poppins;
  color: #cbcbcb;
  text-align: left;
  display: inline-block;
}
@media screen and (max-width: 960px) {
  .copyright {
    font-size: 1rem;
  }
}
@media screen and (max-width: 430px) {
  .copyright {
    font-size: 1rem;
  }
}
